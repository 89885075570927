
import React, { useEffect, useState } from 'react';

import StepOneForm from './StepOneForm';

const StepOne = ({ setStep, step, clientDetails, ownerInfo, setFormData,formData }) => {

    const [usersData,setUsersData]=useState({...formData});
    const [stepOneDetails, setStepOneDetails] = useState(null)
    const stepOneDetailsFromLocalStorage = localStorage.getItem("client-details");
    useEffect(() => {
        if (stepOneDetailsFromLocalStorage) {
            let data = JSON.parse(stepOneDetailsFromLocalStorage);
            if (data) setStepOneDetails(data);
        }
    }, [stepOneDetailsFromLocalStorage])

     const updateStep=()=>{
        setStep(step+1);
     }
    //  update formdata
  
    useEffect(()=>{
        setFormData((pre) => ({ ...pre, ...usersData }))
        
    }, [usersData])
    console.log("=======form======daata=======", formData);
    
    return (
        <div>
            <div>
                <div className="client-logo">
                    <img src={clientDetails?.logo} alt="logo" />
                </div>
            </div>
            <div className="container all-steps">
                { <StepOneForm ownerInfo={ownerInfo} updateStep={updateStep} setUsersData={setUsersData} formData={formData} stepOneDetails={stepOneDetails }/>}
            </div>
        </div>
    );
};

export default StepOne;
