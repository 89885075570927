import React, { useEffect, useState } from 'react'
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import { useLocation, useParams } from 'react-router-dom';
import user from "../assests/user.png"
import bag from "../assests/bag.png"
import complete from "../assests/completed.png"
import loadingImg from "../assests/loading.png"
import coin from "../assests/coin.png"
import disclaimer from "../assests/disclaimer.png"
import axios from 'axios';

import Application from '../Application';
import { Puff } from 'react-loader-spinner';

const apiUrl = process.env.REACT_APP_API_URL;
// const sitekey = process.env.REACT_APP_SITEKEY;

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const loadGoogleMapsScript = (callback) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback();
  } else {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAy0bpCeEhs9ctu2Y0EBJktyutfJ8bwx0A&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.head.appendChild(script);
  }
};

const OnboardingMain = () => {
  const { clientkey } = useParams();
  const [onboardingData, setOnboardingData] = useState('')
  const clientInfo = useLocation();
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  // console.log("clientInfo============", clientInfo?.state?.clientDetails);
  // const [maskedData, setMaskedData] = useState("");
  const [clientDetails, setClientDetails] = useState("");
  //  const clientDtls = clientInfo?.state?.clientDetails;
  const [step, setStep] = useState(0);
  let localStorageData = localStorage.getItem('client-details')

  const [formData, setFormData] = useState({
    ownership_detail: [],
    business_address: '',
    business_city: '',
    business_state: '',
    business_zip: '',
    business_country: '',
    business_industry: '',
    business_name: '',
    business_structure: 'Private Corporation',
    website: 'https://www.abc.com',
    processing_environment: 'ecommerce',
    avgTicket: '',
    annualCCSales: '',
    established_date: '2000-01-01',
    dba: '',
    ein: '',
    routing_number: '',
    account_number: '',
    bank_name: 'Test Bank',
    ownershiptype: 'CORPORATION',
    legal_b2c: '',
    legal_b2b: '',
    legal_cnpinternet: '',
    legal_cnpphoneemail: '',
    bussiness_to_consumer: '',
    bussiness_to_business: '',
    legal_percentdeliveredover30days: '',
    legal_percentdeliveredsameday: '',
    percentdelievered7days: '',
    percentdelivered14days: '',
    legal_cp: '',
    legal_productssold: '',
    file: null,
  });

  useEffect(() => {
    if (localStorageData) {
      let data = JSON.parse(localStorageData);
      setOnboardingData(data);
    }
  }, [localStorageData])
   
  // steps conten name, icon
  const stepsContent = [    
    {name:"Owner information",icon:user,id:1},
    {name:'Business details',icon:bag,id:2},
    {name:'Processing information',icon:loadingImg,id:3},
    {name:'Banking information',icon:coin,id:4},
    // {name:'Disclaimer',icon:disclaimer,id:5},
   ];


  // get client details client logo and name
  const getClientDetails = async () => {

    try {
      const response = await axios.get(`${apiUrl}/hosted/${clientkey}`);
      setClientDetails(response?.data?.data)
      // console.log(response)
      // toast.error("Something went wrong")
    } catch (error) {
      console.log("eroorhello",error);
    }
  }
  useEffect(() => {
    getClientDetails();
  }, [])

  useEffect(() => {
    loadGoogleMapsScript(() => {
      setScriptLoaded(true);
    });
  }, []);

  if (!isScriptLoaded) {
    return <div className="loader-container ">
      <>
        <div className="loader-background"></div>
        <div className="loader-content">
          <Puff color="#ffffff" height={100} width={100} />
        </div>
      </>
    </div>
  }

  console.log("===========CLIENTDAT", clientInfo?.state?.clientDetails);

  return (
    <>
      <ul className="steps">
        {stepsContent?.map((item)=>{
          return(
            <li key={item?.id} className={`step ${item.id < step ? "complete":(item.id > step ?"" :"active")}`} data-step={step}><span><img src={item.icon} className="normal" /> <img src={complete} className="done" /> <div className='step-text'> {item?.name?.split(" ")[0]} <small>{item?.name?.split(" ")[1]}</small></div></span></li>
          )
        })}
        </ul>
     

      {step === 0 && <div className="container all-steps"> <Application  setStep={setStep} step={step} /> </div>}
      {step === 1 && <StepOne setStep={setStep} step={step} clientDetails={clientDetails} ownerInfo={clientInfo?.state?.clientDetails} setFormData={setFormData} formData={formData} />}
      {step === 2 && <StepTwo setStep={setStep} step={step} clientDetails={clientDetails} ownerInfo={onboardingData} setFormData={setFormData} formData={formData} />}
      {step === 3 && <StepThree setStep={setStep} step={step} clientDetails={clientDetails} ownerInfo={onboardingData} setFormData={setFormData} formData={formData} />}
      {step === 4 && <StepFour setStep={setStep} step={step} clientDetails={clientDetails} ownerInfo={onboardingData} setFormData={setFormData} formData={formData} />}
      {step === 5 && <StepFive setStep={setStep} step={step} clientDetails={clientDetails} ownerInfo={onboardingData} setFormData={setFormData} formData={formData} />}
     
    </>
  )
}

export default OnboardingMain