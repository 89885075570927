import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { dataIndustries } from '../dataIndustries';
import { useParams } from 'react-router-dom';
import { Puff } from 'react-loader-spinner';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import Select from 'react-select';


const StepTwo = ({ setStep, step, clientDetails, ownerInfo, setFormData, }) => {
  const { clientkey, merchant_id } = useParams();
  // const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [loading, setLoading] = useState(false);


  // if (!isScriptLoaded) {
  //   return <div>Loading...</div>; 
  // }
  console.log("owner info", ownerInfo);
  const apiUrl = process.env.REACT_APP_API_URL;


  const initialValues = {
    business_name: ownerInfo ? ownerInfo?.business_name : '',
    business_address: ownerInfo ? ownerInfo?.business_address : '',
    // ownershiptype: ownerInfo ? ownerInfo?.ownershiptype : '',
    business_structure: ownerInfo ? ownerInfo?.business_structure : '',
    business_state: ownerInfo ? ownerInfo?.business_state : '',
    business_city: ownerInfo ? ownerInfo?.business_city : '',
    business_zip: ownerInfo ? ownerInfo?.business_zip : '',
    business_country: ownerInfo ? ownerInfo?.business_country : '',
    ein: ownerInfo?.ein ? ownerInfo?.ein : '',
    website: ownerInfo ? ownerInfo?.website : '',
    established_date: ownerInfo ? ownerInfo?.established_date : '',
    business_industry: ownerInfo ? ownerInfo?.business_industry : '',
    legal_productssold: ownerInfo ? ownerInfo?.legal_productssold : '',
    processing_environment: ownerInfo ? ownerInfo?.processing_environment : '',
    fax_two: ''
  }
  const validationSchema = Yup.object({
    business_name: Yup.string().required('Business name is required'),
    business_address: Yup.string().required('Business address is required'),
    business_structure: Yup.string().required('Company structure is required'),
    // ownershiptype: Yup.string().required('Business type is required'),
    business_state: Yup.string().required('State is required'),
    business_city: Yup.string().required('City is required'),
    business_country: Yup.string().required('Country is required'),
    business_zip: Yup.string().required('Zip code is required'),
    ein: Yup.string().matches(/^\d{9}$/, "Invalid EIN").required('EIN or TAX ID is required'),
    website: Yup.string().matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+)(\.[a-zA-Z]{2,})+$/, "Invalid url").required('Website is required'),
    established_date: Yup.date().required('Established date is required'),
    business_industry: Yup.string().required('Industry is required'),
    processing_environment: Yup.string().required('Processing Environment is required'),
    legal_productssold: Yup.string().required('Products sold is required'),
    fax_two: Yup.string()
  });


  const handleSelect = async (address, setFieldValue) => {
    setFieldValue('business_address', address?.split(",")[0]);
    const results = await geocodeByAddress(address);
    const { address_components } = results[0];
    address_components.forEach(component => {
      const { types, short_name } = component;
      if (types.includes('locality')) {
        setFieldValue('business_city', short_name);
      } else if (types.includes('administrative_area_level_1')) {
        setFieldValue('business_state', short_name);
      } else if (types.includes('country')) {
        setFieldValue('business_country', short_name);
      } else if (types.includes('postal_code')) {
        setFieldValue('business_zip', short_name);
      }
    });
    try {
      // const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error('Error fetching address coordinates:', error);
    }
  };

  // handle submit form
  const handleSubmitSecondStep = async (values) => {
    try {
      let data = {
        ...values,
        merchant_id,
        step: 2,
      }
      setLoading(true);
      let saveStepTwoToLocalStorage = JSON.stringify({ ...ownerInfo, ...values })
      localStorage.setItem('client-details', saveStepTwoToLocalStorage);
      // console.log("=========steop onoe", { ...ownerInfo, ...values });

      // return
      if (merchant_id) {
        const response = await axios.post(
          `${apiUrl}/merchant/onboarding/${clientkey}`, data);
        if (response.data.result) {
          toast.success(response?.data?.message);
          // console.log("response", response.data.data);
          setStep(step + 1);
        }
        else {
          toast.error(response?.data?.message);
        }
      }
      else {
        setFormData((pre) => ({ ...pre, ...values }))
        setStep(step + 1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    }
  }


  return (
    <div className="">

      <div className='client-logo'>
        <img src={clientDetails?.logo} alt='logo' />
      </div>
      <div className="container all-steps">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            // check bot
            if (values?.fax_two !== "") { return false; }
            console.log(values);
          
            handleSubmitSecondStep(values)
            // setStep(step + 1);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="heading-container">
                <div className="heading-text">
                  <h2>Business Details</h2>
                </div>
                <div className="heading-ico">
                  <img src="/business-details.png" alt="Owner Info" />
                </div>
              </div>

              <div className="row">

                <div className='input-coll'>
                  <label className='labelStyle'>Business name*</label>
                  <Field className='fields' type="text" name="business_name" placeholder='Business name' />
                  <ErrorMessage name='business_name' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>Company Structure</label>
                  <Field as="select" name='business_structure' className='fields'>
                    <option value="">Select Company Structure</option>
                    <option value="Sole Prop">Sole Prop</option>
                    <option value="LLC">LLC</option>
                    <option value="Private Corporation">Private Corporation</option>
                    <option value="Public Corporation">Public Corporation</option>
                    <option value="Non profit">Non Profit</option>
                    <option value="limited liability partnership">Limited Liability Partnership</option>
                  </Field>
                  <ErrorMessage name='business_structure' component='div' className='error' />
                </div>


                {/* <div className='input-coll'>
                  <label className='labelStyle'>Business Type</label>
                  <Field as="select" name='ownershiptype' className='fields'>
                    <option value="">Select Business Type</option>
                    <option value="GOVERNMENT">Government</option>
                    <option value="JOINT STOCK">Joint Stock</option>
                    <option value="LIMITED">Limited</option>
                    <option value="NON PROFIT ORG">Non Profit Org</option>
                    <option value="PARTNERSHIP">Partnership</option>
                    <option value="CORPORATION">Corporation</option>
                    <option value="PUBLIC COMPANY">Public Company</option>
                    <option value="SOLE PROPRIETOR">Sole Proprietor</option>
                  </Field>
                  <ErrorMessage name='ownershiptype' component='div' className='error' />
                </div> */}
              </div>
              <div className="row">
                <div className='input-coll-full'>
                  <label className='labelStyle'>Business Address</label>
                  <PlacesAutocomplete
                    value={values?.business_address}
                    onChange={(text) => setFieldValue('business_address', text)}
                    onSelect={(text) => handleSelect(text, setFieldValue)}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input className='fields' {...getInputProps({ placeholder: 'Search business address' })} />
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, id) => {
                            const style = {
                              backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              border: suggestion.active ? '1px solid #41b6e6' : '1px solid #ccc',
                              cursor: "pointer",
                              marginTop: "5px"
                            };
                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })} key={id}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <ErrorMessage name='business_address' component='div' className='error' />
                </div>
              </div>
              <div className="row">
                <div className='input-coll'>
                  <label className='labelStyle'>State</label>
                  <Field className='fields' type="text" name="business_state" placeholder='State' />
                  <ErrorMessage name='business_state' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>City</label>
                  <Field className='fields' type="text" name="business_city" placeholder='City' />
                  <ErrorMessage name='business_city' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>Zip Code</label>
                  <Field className='fields' type="text" name="business_zip" placeholder='Zip Code' />
                  <ErrorMessage name='business_zip' component='div' className='error' />
                </div>
                <div className='input-coll'>
                  <label className='labelStyle'>Country</label>
                  <Field className='fields' type="text" name="business_country" placeholder='Country' />
                  <ErrorMessage name='business_country' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>EIN or TAX ID*</label>
                  <Field maxLength={9} className='fields' type='text' name='ein' placeholder='Employer Identification Number' />
                  <ErrorMessage name='ein' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>Processing environment</label>
                  <Field as="select" name="processing_environment" className='fields'

                  >
                    <option value="">Select Processing Environment</option>
                    <option value="cardPresent">Card Present</option>
                    <option value="ecommerce">E-Commerce</option>
                    <option value="fuel">Fuel</option>
                    <option value="moto">Mail Order/Telephone Order</option>
                    <option value="restaurant">Restaurant</option>
                    <option value="serviceStation">Service Station</option>
                    <option value="supermarket">Supermarket</option>
                  </Field>
                  <ErrorMessage name='processing_environment' component='div' className='error' />
                </div>
                <div className='input-coll'>
                  <label className='labelStyle'>Website</label>
                  <Field className='fields' type="text" name="website" placeholder='Website link' />
                  <ErrorMessage name='website' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>Established Date</label>

                  <DatePicker
                    selected={values.established_date ? moment(values.established_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(date) => setFieldValue('established_date', date ? moment(date).format('YYYY-MM-DD') : '')}
                    className='fields'
                    dateFormat="yyyy-MM-dd"
                    placeholderText='YYYY-MM-DD'
                  />

                  <ErrorMessage name='established_date' component='div' className='error' />
                </div>

                <div className='input-coll'>
                  <label className='labelStyle'>Industry</label>

                  <Select
                    className="basic-single fields"
                    classNamePrefix="select"
                    value={
                      dataIndustries.find(option => option.value === values.business_industry) || null
                    }
                    onChange={(data) => {
                      setFieldValue('business_industry', data?.value);
                    }}
                    options={dataIndustries}
                  />

                  <ErrorMessage name='business_industry' component='div' className='error' />
                </div>
                <div className='input-coll'>
                  <label className='labelStyle'>Products Sold</label>
                  <Field className='fields' type="string" name="legal_productssold" placeholder='Products' />
                  <ErrorMessage name='legal_productssold' component='div' className='error' />
                </div>

                <div className="input-coll">
                  <Field type="text" name={`fax_two`} style={{ display: "none !important" }} hidden />
                </div>
                <div className='Btn1div'>
                  <button type='button' onClick={() => setStep(step - 1)} className='PrevBtn'>Previous</button>
                  <button type="submit" className='FirstPgBtn '>Continue</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loading && (
        <div className="loader-container ">
          <>
            <div className="loader-background"></div>
            <div className="loader-content">
              <Puff color="#ffffff" height={100} width={100} />
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default StepTwo;
