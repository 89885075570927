import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SuccessPage = () => {
  const location = useLocation();
useEffect(()=>{
// localStorage.clear();
},[])
  return (
    <div className='row'>
        <div className="container all-steps final ">
    <div className='succ-regi'>
      {/* <h1 style={styles.header}>Application Submitted Successfully!</h1>
      <p>Thank you for applying for payments on Client Name. Please check your email to accept our digital terms and conditions.</p>
      <p>It will take 24-48 business hours to review your application. We appreciate your patience.</p>
       */}
      <div className='text'>
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_8413_40551)">
            <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_8413_40551)" />
            <rect x="10" y="25.3369" width="7" height="16.8269" rx="3.5" transform="rotate(-44.9454 10 25.3369)" fill="url(#paint1_linear_8413_40551)" />
            <rect x="34.9609" y="14" width="7" height="25.6115" rx="3.5" transform="rotate(42.5801 34.9609 14)" fill="url(#paint2_linear_8413_40551)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_8413_40551" x1="4.86111" y1="-3.47222" x2="45.8333" y2="54.8611" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" />
              <stop offset="0.127051" stop-color="#C7A4FF" stop-opacity="0.59" />
              <stop offset="0.572861" stop-color="#EEE4FF" stop-opacity="0.56" />
              <stop offset="0.885175" stop-color="white" />
            </linearGradient>
            <linearGradient id="paint1_linear_8413_40551" x1="18.445" y1="34.5703" x2="13.1831" y2="36.4539" gradientUnits="userSpaceOnUse">
              <stop stop-color="#CFBBEF" />
              <stop offset="1" stop-color="#B697E7" />
            </linearGradient>
            <linearGradient id="paint2_linear_8413_40551" x1="41.9609" y1="26.8058" x2="34.0968" y2="27.5693" gradientUnits="userSpaceOnUse">
              <stop stop-color="#CFBBEF" />
              <stop offset="1" stop-color="#B697E7" />
            </linearGradient>
            <clipPath id="clip0_8413_40551">
              <rect width="50" height="50" rx="7.3873" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <h1>Congratulations</h1>
        <p>Your application has been received. <br/> We will be in touch by email once you have been approved..</p>
      </div>

          <button onClick={() => {  window.close()}}>Close</button>
    </div>
    </div>
    </div>
  );
}



export default SuccessPage;