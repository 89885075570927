import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Puff } from 'react-loader-spinner';

const StepThree = ({ setStep, step, clientDetails, ownerInfo, setFormData }) => {
  console.log("=owner",ownerInfo);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { clientkey, merchant_id } = useParams();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    avgTicket: ownerInfo?.avgTicket ? ownerInfo?.avgTicket : '',
    annualCCSales: ownerInfo?.annualCCSales ? ownerInfo?.annualCCSales : '',
    legal_b2c: ownerInfo?.legal_b2c ? ownerInfo?.legal_b2c : '',
    legal_b2b: ownerInfo?.legal_b2b ? ownerInfo?.legal_b2b : '',
    legal_cnpinternet: ownerInfo?.legal_cnpinternet ? ownerInfo?.legal_cnpinternet : '',
    legal_cnpphoneemail: ownerInfo?.legal_cnpphoneemail ? ownerInfo?.legal_cnpphoneemail : '',
    legal_percentdeliveredover30days: ownerInfo?.legal_percentdeliveredover30days ? ownerInfo?.legal_percentdeliveredover30days : '',
    legal_percentdeliveredsameday: ownerInfo?.legal_percentdeliveredsameday ? ownerInfo?.legal_percentdeliveredsameday : '',
    percentdelievered7days: ownerInfo?.percentdelievered7days ? ownerInfo?.percentdelievered7days : '',
    percentdelivered14days: ownerInfo?.percentdelivered14days ? ownerInfo?.percentdelivered14days : '',
    legal_cp: ownerInfo?.legal_cp ? ownerInfo?.legal_cp : '',
    legal_keyed: ownerInfo?.legal_keyed ? ownerInfo?.legal_keyed : '',
    fax_three:''
  };

  const validationSchema = Yup.object({
    avgTicket: Yup.number().typeError('Average ticket must be a number').required('Average ticket is required'),
    annualCCSales: Yup.number().typeError('Annual Credit Card Sales must be a number').required('Annual Credit Card Sales is required'),
    legal_b2c: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('B2C Transactions must be a number').required('B2C Transactions are required'),
    legal_b2b: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('B2B Transactions must be a number').required('B2B Transactions are required'),
    legal_cnpinternet: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Manual Entry via Internet must be a number').required('Manual Entry via Internet is required'),
    legal_cnpphoneemail: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Manual Entry via Phone/Email must be a number').required('Manual Entry via Phone/Email is required'),
    legal_percentdeliveredover30days: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Deferred Delivery > 30 Days must be a number').required('Deferred Delivery > 30 Days is required'),
    legal_percentdeliveredsameday: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Same-Day Delivery must be a number').required('Same-Day Delivery is required'),
    percentdelievered7days: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Deferred Delivery > 7 Days must be a number').required('Deferred Delivery > 7 Days is required'),
    percentdelivered14days: Yup.number().min(0,"Should be minimum 0").max(100,"Cannot exceed 100").typeError('Deferred Delivery > 14 Days must be a number').required('Deferred Delivery > 14 Days is required'),
    legal_cp: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Card Present must be a number').required('Card Present is required'),
    legal_keyed: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Card Not Present must be a number').required('Card Not Present is required'),
   fax_three:Yup.string()
  });

  // handle form submit form
  
  const stepThirdHandler = async (values) => {
    try {
      values = {
        ...values,
         avgTicket: Number(values?.avgTicket),
        annualCCSales: Number(values?.annualCCSales),
       }
      let data = {
       ...values,
        merchant_id,
        step: 3,
      }
      let saveStepTwoToLocalStorage = JSON.stringify({ ...ownerInfo, ...values })
      localStorage.setItem('client-details', saveStepTwoToLocalStorage);
      //  
      console.log('+=============vlaues', { ...ownerInfo, ...values });
      
      setLoading(true);
      if(merchant_id){
        
        const response = await axios.post(
          `${apiUrl}/merchant/onboarding/${clientkey}`, data);
          if (response.data.result) {
            console.log("response", response.data.data);
            toast.success(response?.data?.message);
            setStep(step + 1);
          }
          else{
            toast.error(response?.data?.message);
            
          }
        }
        else{
          setFormData((pre) => ({ ...pre, ...values }))
          setStep(step + 1);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message || "Something went wrong");
        console.log(error);
      }
    }

    // validate fields
    const ValidateFields=(values)=>{
      const errors = {};

      if (Number(values.legal_b2c) + Number(values.legal_b2b) !== 100) {
        errors.legal_b2c = 'B2B and B2C should total 100%';
        errors.legal_b2b = 'B2B and B2C should total 100%';
      }
      if (Number(values.legal_cp) + Number(values.legal_keyed) !== 100) {
        errors.legal_cp = 'Card Present and Card Not Present should total 100%';
        errors.legal_keyed = 'Card Present and Card Not Present should total 100%';
      }
      if (Number(values.legal_cnpinternet) +Number(values.legal_cnpphoneemail)  !== 100) {
        errors.legal_cnpinternet  = 'Manual Entry via Phone/Email, internet should total 100%';
        errors.legal_cnpphoneemail  = 'Manual Entry via Phone/Email, internet should total 100%';
      }
      if (Number(values.legal_percentdeliveredover30days) + Number(values.legal_percentdeliveredsameday) + Number(values.percentdelivered14days) + Number(values.percentdelievered7days)  !== 100) {
        errors.legal_percentdeliveredover30days = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
        errors.legal_percentdeliveredsameday = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
        errors.percentdelivered14days  = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
        errors.percentdelievered7days  = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
      }
      
   
      return errors;
  
    }
  return (
    <div className="">
      <div className='client-logo'>
        <img src={clientDetails?.logo} alt='logo' />
      </div>
      <div className="container all-steps">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}

          validate={(values) => {
            
          let errors=  ValidateFields(values);
           
            return errors;
          }}
          onSubmit={(values) => {
            console.log(values);
            // check bot
            if (values?.fax_three !== "") { return false; }

            // return
            stepThirdHandler(values);
            // setStep(step + 1);
          }}
        >
          {({  values }) => (
            <Form>
              <div className="heading-container">
                <div className="heading-text">
                  <h2>Processing information</h2>
                </div>
                <div className="heading-ico">
                  <img src="/owner-info.png" alt="Owner Info" />
                </div>
              </div>

              <div className="">
                <div className='row'>
                  <div className='input-coll'>
                    <label className='labelStyle' >Average Ticket</label>
                    <Field className='fields' type="text" name="avgTicket" placeholder='($)'
                      onInput={(e) => {
                        const value = e.target.value;
                        const validInput = value.match(/^\d*\.?\d{0,2}$/);
                        if (!validInput) {
                          e.target.value = value.slice(0, -1); 
                        }
                      }}
                    />

                    <ErrorMessage name='avgTicket' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Annual Credit Card Sales</label>
                    <Field className='fields' type="text" name="annualCCSales" placeholder='($)' 
                    onInput={(e) => {
                      const value = e.target.value;
                      const validInput = value.match(/^\d*\.?\d{0,2}$/);
                      if (!validInput) {
                        e.target.value = value.slice(0, -1);
                      }
                    }} />
                    <ErrorMessage name='annualCCSales' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >B2C Transactions</label>
                    <Field className='fields' type="number" placeholder='a. Cumulative to 100%' name='legal_b2c' maxLength={3}/>
                    <ErrorMessage name='legal_b2c' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >B2B Transactions</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='a. Cumulative to 100%' name='legal_b2b' />
                    <ErrorMessage name='legal_b2b' component='div' className='error' />
                  </div>

                  <div className='input-coll'>
                    <label className='labelStyle' >Card Present %</label>
                    <Field maxLength={3} className='fields' type="text" placeholder='b. Cumulative to 100%' name='legal_cp' />
                    <ErrorMessage name='legal_cp' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Card Not Present %</label>
                    <Field maxLength={3} className='fields' type="text" placeholder='b. Cumulative to 100%' name='legal_keyed' />
                    <ErrorMessage name='legal_keyed' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Manual Entry via Phone/Email</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='c. Cumulative to 100%' name='legal_cnpphoneemail' />
                    <ErrorMessage name='legal_cnpphoneemail' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Manual Entry via Internet</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='c. Cumulative to 100%' name='legal_cnpinternet' />
                    <ErrorMessage name='legal_cnpinternet' component='div' className='error' />
                  </div>
                </div>

                <div className='heading-text mt-all'>
                  <h2>Post-Transaction Info</h2>
                </div>

                <br />
                <div className='row'>
                  <div className='input-coll'>
                    <label className='labelStyle' >Same-Day Delivery</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='d. Cumulative to 100%' name='legal_percentdeliveredsameday' />
                    <ErrorMessage name='legal_percentdeliveredsameday' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Deferred Delivery {'>'} 7 Days</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='d. Cumulative to 100%' name='percentdelievered7days' />
                    <ErrorMessage name='percentdelievered7days' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Deferred Delivery {'>'} 14 Days</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='d. Cumulative to 100%' name='percentdelivered14days' />
                    <ErrorMessage name='percentdelivered14days' component='div' className='error' />
                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Deferred Delivery {'>'} 30 Days</label>
                    <Field maxLength={3} className='fields' type="number" placeholder='d. Cumulative to 100%' name='legal_percentdeliveredover30days' />
                    <ErrorMessage name='legal_percentdeliveredover30days' component='div' className='error' />
                  </div>
                </div>
                <div className="input-coll">
                  <Field type="text" name={`fax_three`} style={{ display: "none !important" }} hidden />
                </div>
                <div className='Btn1div'>
                  <button type='button' onClick={() => setStep(step - 1)} className='PrevBtn'>Previous</button>
                  <button type="submit" className='FirstPgBtn '>Continue</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loading && (
        <div className="loader-container ">
          <>
            <div className="loader-background"></div> {/* Background blur */}
            <div className="loader-content">
              <Puff color="#ffffff" height={100} width={100} />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default StepThree;
